






import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AdminProviderDetails',
  components: {}

})
export default class AdminProviderDetails extends Vue {
  @Prop() private uid!: string;

  // private providerItem :ProviderItem = emptyProviderItem()
}
